/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #1d1d1d;
  color: #fff;
  overflow-x: hidden;
}

.mat-form-field-label{
  color:#fff !important;
}
.mat-form-field-underline{
  background-color:#fff !important;
}

.success{
  background-color: #4BB543;
}

.error{
  background-color: #cc0000;
}

.mat-form-field-underline{
  background-color: greenyellow !important;
}

.mat-form-field-label{
  color: greenyellow !important;
}

